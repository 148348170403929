import {
  postScheduleEnabled,
  postSchedule,
  postTemperatureRequired
} from 'api';
import { AxiosError } from 'axios';
import { Button, Schedule, Spinner, Temperature, Weekday } from 'components';
import { POLLING_INTERVAL } from 'config/temperature';
import { format } from 'date-fns';
import {
  useAuth,
  useScheduleEnabled,
  useSchedule,
  useTemperatureCurrent,
  useTemperatureRequired
} from 'hooks';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import {
  ApiResponseError,
  ScheduleEnabledResponse,
  ScheduleResponse,
  TemperatureRequiredResponse
} from 'typings';
import { ReactComponent as AccountCircle } from './account-circle.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as LogoSmartpool } from './logo-smartpool-horizontal-light.svg';
import './style.css';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

export const HomeScreen = () => {
  const queryClient = useQueryClient();

  const { signout } = useAuth();

  const scheduleQuery = useSchedule();
  const scheduleMutation = useMutation<
    ScheduleResponse,
    AxiosError<ApiResponseError>,
    ScheduleResponse
  >(postSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries('schedule');
    }
  });

  const days = scheduleQuery.data || [];

  const temperatureCurrent = useTemperatureCurrent({
    refetchInterval: POLLING_INTERVAL
  });

  const temperatureRequiredQuery = useTemperatureRequired();
  const temperatureRequiredMutation = useMutation<
    TemperatureRequiredResponse,
    AxiosError<ApiResponseError>,
    TemperatureRequiredResponse
  >(postTemperatureRequired, {
    onSuccess: () => {
      queryClient.invalidateQueries('temperature-required');
    }
  });

  const scheduleEnabledQuery = useScheduleEnabled();
  const scheduleEnabledMutation = useMutation<
    ScheduleEnabledResponse,
    AxiosError<ApiResponseError>,
    ScheduleEnabledResponse
  >(postScheduleEnabled, {
    onSuccess: () => {
      queryClient.invalidateQueries('hibernation-state');
    }
  });

  const lastUpdated =
    temperatureCurrent.data?.updatedAt &&
    new Date(temperatureCurrent.data?.updatedAt * 1000);

  const [updatedSlots, setUpdatedSlots] = useState<number[]>();
  const isHibernating = scheduleEnabledQuery.data?.state;

  const history = useHistory();
  const { day: currentDay } = useParams<{ day: string }>();

  const daySelected = parseInt(currentDay, 10);
  const selectedDay = days[daySelected];

  const currentSlots = updatedSlots?.length ? updatedSlots : selectedDay?.slots;

  const handleToggleSlot = (slot: number) => {
    const toggled = slot + 1;

    const newSlots = currentSlots.includes(toggled)
      ? currentSlots.filter((i) => i !== toggled)
      : [...currentSlots, toggled];

    const newSchedule = [...days];
    newSchedule[daySelected].slots = newSlots;
    scheduleMutation.mutate(newSchedule);
  };

  const handleChangeTemperature = (temperature: number) => {
    temperatureRequiredMutation.mutate({
      temperature
    });
  };

  return (
    <div className="bg-gray-100 flex flex-col h-screen overflow-auto">
      {scheduleQuery.isLoading && (
        <div className="flex flex-col items-center justify-center h-full">
          <Spinner />
          <LogoSmartpool className="mx-auto my-8" />
        </div>
      )}
      {scheduleQuery.data && (
        <>
          <div className="md:w-screen-md md:mx-auto">
            <div className="flex justify-between items-start w-full px-4 py-10">
              <div>
                <p className="font-medium opacity-50 text-black text-sm">
                  Huidige Temperatuur
                </p>
                {temperatureCurrent.data?.temperature && (
                  <>
                    <Temperature
                      className="text-primary"
                      size={80}
                      temperature={temperatureCurrent.data?.temperature}
                    />
                    {lastUpdated && (
                      <p className="text-xxs text-gray-400">
                        {`Laatste update: ${format(
                          lastUpdated,
                          'dd-MM-yyyy HH:mm:ss'
                        )}`}
                      </p>
                    )}
                  </>
                )}
              </div>
              <Button
                onClick={() => {
                  signout?.();
                }}
                variant="ghost"
              >
                <AccountCircle />
              </Button>
            </div>
            <div className="bg-white px-4 py-6 rounded-t-2xl shadow-lg">
              <p className="font-medium	 text-center text-sm">
                Gewenste gebruikstemperatuur
              </p>
              {temperatureRequiredQuery.data && (
                <div className="border mb-8 mt-4 p-2 rounded-xl">
                  <div className="flex justify-between">
                    <Button
                      onClick={() => {
                        handleChangeTemperature(
                          temperatureRequiredQuery.data.temperature - 0.5
                        );
                      }}
                      title="-"
                    />
                    <div className="flex flex-col items-center">
                      <Temperature
                        className="text-black"
                        size={40}
                        temperature={temperatureRequiredQuery.data.temperature}
                      />
                      <p className="text-xs">gebruikerstemperatuur</p>
                    </div>
                    <Button
                      onClick={() => {
                        handleChangeTemperature(
                          temperatureRequiredQuery.data.temperature + 0.5
                        );
                      }}
                      title="+"
                    />
                  </div>
                </div>
              )}
              {!scheduleEnabledQuery.data?.state && (
                <>
                  <p className="text-center text-sm">Weekschema</p>
                  <div className="flex justify-center mt-4 mb-4">
                    <div className="flex text-xs items-center mr-4 text-gray-700">
                      <div className="bg-primary h-2 mr-1 rounded-sm w-2" />
                      Gebruikerstemperatuur
                    </div>
                    <div className="flex text-xs items-center text-gray-700">
                      <div className="bg-gray-200 h-2 mr-1 rounded-sm w-2" />
                      Eco-spaarstand
                    </div>
                  </div>
                  <div className="mb-12">
                    {days.map((day, index) => (
                      <Weekday
                        day={day}
                        key={day.id}
                        onEdit={() => {
                          setUpdatedSlots([]);
                          history.push(`/${index}`);
                        }}
                      />
                    ))}
                  </div>
                </>
              )}
              <h2 className="text-lg text-black opacity:">
                Slaapstand ({isHibernating ? 'ingeschakeld' : 'uitgeschakeld'})
              </h2>
              <p className="text-xs text-gray-500 mb-8">
                Door de slaapstand aan te schakelen schakel je de slimme
                verwarming uit, en zal de ingestelde temperatuur aangehouden
                worden.
              </p>
              <Button
                onClick={() => {
                  scheduleEnabledMutation.mutate({
                    state: !isHibernating
                  });
                }}
                variant="default"
                title={
                  isHibernating
                    ? 'Slaapstand uitschakelen'
                    : 'Slaapstand inschakelen'
                }
              />
              <LogoSmartpool className="mx-auto my-8" />
            </div>
          </div>
          <ReactModal
            className="react-modal-modal"
            contentLabel="Edit day schedule"
            isOpen={daySelected >= 0}
            overlayClassName="react-modal-overlay"
          >
            {selectedDay && (
              <div
                className="h-screen"
                style={{
                  boxShadow:
                    '0px 8px 40px 0px rgba(0, 0, 0, 0.25),0px 0px 3px 0px rgba(0, 0, 0, 0.55)'
                }}
              >
                <header
                  className="flex items-center fixed top-0 md:relative w-full bg-white justify-between px-8 py-4 h-16 z-20"
                  style={{
                    boxShadow: 'inset 0px -1px 0px 0px rgb(232, 232, 232)'
                  }}
                >
                  <Button onClick={() => history.push('/')} variant="ghost">
                    <ArrowLeft />
                  </Button>
                  <div className="font-medium text-lg">{selectedDay.title}</div>
                  <Button
                    className="text-red-500"
                    onClick={() => history.push('/')}
                    variant="ghost"
                  >
                    <div
                      style={{
                        color: '#ff3b30',
                        fontSize: 17,
                        letterSpacing: '-0.41px'
                      }}
                    >
                      Annuleren
                    </div>
                  </Button>
                </header>
                <div className="h-full pt-16 md:pt-0 pb-0 relative z-10">
                  {(scheduleMutation.isLoading || scheduleQuery.isLoading) && (
                    <div className="absolute bg-white bg-opacity-60 w-full h-full z-10">
                      <Spinner />
                    </div>
                  )}
                  <div className="h-full p-8 overflow-auto">
                    <Schedule
                      onToggleSlot={handleToggleSlot}
                      slots={currentSlots}
                    />
                  </div>
                </div>
              </div>
            )}
          </ReactModal>
        </>
      )}
    </div>
  );
};

import { API_URL } from 'config/api';
import { MOCKS_ENABLED } from 'config/mock';
import { rest } from 'msw';
import {
  ScheduleEnabledResponse,
  ScheduleResponse,
  TemperatureRequiredResponse
} from 'typings';
import { MOCK_SCHEDULE } from './data/schedule';

let temperatureRequired = 22;
let scheduleEnabled = false;
let schedule = MOCK_SCHEDULE;

/**
 * Mocked response can be added here
 */
export const handlers = MOCKS_ENABLED
  ? [
      rest.get(`${API_URL}/schedule`, (req, res, ctx) =>
        res(ctx.delay(50), ctx.status(200), ctx.json(schedule))
      ),
      rest.post(`${API_URL}/schedule`, (req, res, ctx) => {
        schedule = req.body as ScheduleResponse;
        return res(ctx.delay(50), ctx.status(200), ctx.json(schedule));
      }),
      rest.get(`${API_URL}/temperature-current`, (req, res, ctx) => {
        const temperatures = [21, 22, 23, 24, 25, 26];
        const temperature =
          temperatures[Math.floor(Math.random() * temperatures.length)];
        const response = {
          temperature,
          updatedAt: new Date().getTime() / 1000
        };

        return res(ctx.delay(100), ctx.status(200), ctx.json(response));
      }),
      rest.get(`${API_URL}/temperature-required`, (req, res, ctx) => {
        return res(
          ctx.delay(50),
          ctx.status(200),
          ctx.json({
            temperature: temperatureRequired
          })
        );
      }),
      rest.post(`${API_URL}/temperature-required`, (req, res, ctx) => {
        const { temperature } = req.body as TemperatureRequiredResponse;
        temperatureRequired = temperature;
        return res(
          ctx.delay(100),
          ctx.status(200),
          ctx.json({
            temperature: temperatureRequired
          })
        );
      }),
      rest.get(`${API_URL}/schedule-enabled`, (req, res, ctx) =>
        res(
          ctx.delay(100),
          ctx.status(200),
          ctx.json({
            state: scheduleEnabled
          })
        )
      ),
      rest.post(`${API_URL}/schedule-enabled`, (req, res, ctx) => {
        const { state } = req.body as ScheduleEnabledResponse;
        scheduleEnabled = state;
        return res(
          ctx.delay(100),
          ctx.status(200),
          ctx.json({
            state: scheduleEnabled
          })
        );
      })
    ]
  : [];

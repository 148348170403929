import { appApi } from 'api';
import {
  TemperatureCurrentResponse,
  TemperatureRequiredResponse
} from 'typings';

export const getTemperatureCurrent = () =>
  appApi
    .get<TemperatureCurrentResponse>(`/temperature-current`)
    .then(({ data }) => data);

export const getTemperatureRequired = () =>
  appApi
    .get<TemperatureCurrentResponse>(`/temperature-required`)
    .then(({ data }) => data);

export const postTemperatureRequired = (data: TemperatureRequiredResponse) =>
  appApi.post(`/temperature-required`, data).then((response) => response.data);

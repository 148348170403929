import { Button } from 'components';
import { SLOTS_AVAILABLE } from 'config/schedule';
import React from 'react';
import { Day } from 'typings';
import { ReactComponent as Pencil } from './pencil.svg';

type Props = {
  day: Day;
  onEdit: () => void;
};

type ChainItem = {
  start: number;
  length: number;
};

export const Weekday: React.FC<Props> = ({ day, onEdit }) => {
  const slotsChains: ChainItem[] = day.slots.reduce(
    (prev: ChainItem[], slot) => {
      if (prev.length === 0) {
        return [
          {
            start: slot,
            length: 1
          }
        ];
      }
      const currentChain = prev.find(
        ({ start, length }) => slot - length === start
      );
      if (currentChain) {
        const currentIndex = prev.indexOf(currentChain);
        return Object.assign([...prev], {
          [currentIndex]: {
            ...currentChain,
            length: currentChain.length + 1
          }
        });
      }
      return [
        ...prev,
        {
          start: slot,
          length: 1
        }
      ];
    },
    []
  );

  return (
    <div
      onClick={onEdit}
      className="border border-gray-200 hover:border-gray-300 cursor-pointer mb-3 last:mb-0 px-3 py-2 rounded-xl transition-colors w-full"
      aria-hidden="true"
    >
      <div className="flex flex-row items-center">
        <div className="flex flex-col w-full">
          <div className="text-sm">{day.title}</div>
          <div className="flex flex-col w-full mt-4 mb-2">
            <div className="flex flex-grow w-full">
              {SLOTS_AVAILABLE.map((slot, index) => {
                const slotActive = day.slots.includes(slot + 1);
                const bgClass = slotActive ? 'bg-primary' : 'bg-gray-200';
                const roundedClass =
                  // eslint-disable-next-line no-nested-ternary
                  index === 0
                    ? 'rounded-tl rounded-bl'
                    : index === SLOTS_AVAILABLE.length - 1
                    ? 'rounded-tr rounded-br'
                    : '';

                const chain = slotsChains.find(
                  ({ start }) => start === slot + 1
                );

                return (
                  <div key={slot} className="flex-grow mt-4 relative">
                    {chain && (
                      <div className="absolute overflow-visible -top-5 text-primary text-xxs whitespace-nowrap">
                        {`${(chain.start - 1) * 2}:00 - ${
                          (chain.start - 1) * 2 + chain.length * 2
                        }:00`}
                      </div>
                    )}
                    <div
                      className={`
                      ${bgClass} 
                      ${roundedClass} 
                      font-medium h-2 mb-1
                    `}
                    />
                    {slot === 0 && (
                      <div className="text-gray-700 text-xxs">00:00</div>
                    )}
                    {slot === 6 && (
                      <div className="text-gray-700 text-xxs">12:00</div>
                    )}
                    {slot === 11 && (
                      <div className="text-gray-700 text-xxs text-right">
                        00:00
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Button
          className="hidden md:block md:ml-8 h-10 w-10"
          onClick={onEdit}
          style={{
            background:
              'linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%) rgb(245, 245, 245)'
          }}
        >
          <Pencil />
        </Button>
      </div>
    </div>
  );
};

import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from 'config/api';

const apiConfig: AxiosRequestConfig = {
  baseURL: API_URL
};

export const appApi = axios.create(apiConfig);

export const setAccessToken = (token: string | null) => {
  if (token) {
    appApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

import { ROUTE } from 'config/auth';
import { useAuth } from 'hooks';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTE.UNAUTHENTICATED,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

import { PrivateRoute } from 'components';
import { UserProvider } from 'hooks';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HomeScreen, LoginScreen } from 'screens';

const queryClient = new QueryClient();
const App: React.FC = () => (
  <Router>
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route path="/login">
            <LoginScreen />
          </Route>
          <PrivateRoute path="/:day?">
            <HomeScreen />
          </PrivateRoute>
        </Switch>
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </UserProvider>
  </Router>
);

export default App;

import { Switch } from 'components';
import { SLOTS_AVAILABLE } from 'config/schedule';

type Props = {
  slots: number[];
  onToggleSlot: (slot: number) => void;
};

type ItemProps = {
  active: boolean;
  className?: string;
  handleToggle: (val: number) => void;
  slot: number;
  title: string;
};

const ScheduleItem: React.FC<ItemProps> = ({
  active,
  className,
  handleToggle,
  slot,
  title,
  ...props
}) => {
  const bgClass = active ? 'bg-white' : 'bg-gray-200';
  const hilightClass = active ? 'bg-orange-300' : 'bg-gray-300';
  const shadowClass = active ? 'shadow-custom' : 'shadow-none';
  return (
    <div
      {...props}
      aria-hidden="true"
      className={`
        ${bgClass} 
        ${shadowClass} 
        cursor-pointer flex flex-row items-center justify-between mb-3 last:mb-0 p-4 rounded-xl transition-all
        ${className}
      `}
      onClick={() => {
        handleToggle(slot);
      }}
    >
      <div className="flex flex-row items-center justify-start">
        <div
          className={`
            ${hilightClass} 
            w-1 h-8 mr-4
          `}
        />
        <div className="text-gray-400 text-sm">{title}</div>
      </div>
      <Switch id={slot.toString()} isOn={active} onChange={() => {}} />
    </div>
  );
};

const addZeros = (time: number) => (time < 10 ? `0${time}` : time);
const slotToTime = (slot: number) => {
  const start = slot * 2;
  const end = (slot + 1) * 2;
  return `${addZeros(start)}:00 -  ${addZeros(end)}:00`;
};

export const Schedule: React.FC<Props> = ({ slots, onToggleSlot }) => (
  <div>
    {SLOTS_AVAILABLE.map((slot) => (
      <ScheduleItem
        active={slots.includes(slot + 1)}
        handleToggle={onToggleSlot}
        key={slot}
        slot={slot}
        title={slotToTime(slot)}
      />
    ))}
  </div>
);

const {
  REACT_APP_AUTH_HOST,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REDIRECT_URI
} = process.env;

export const AUTH_HOST =
  REACT_APP_AUTH_HOST || 'smartpool.auth.eu-west-1.amazoncognito.com';

export const AUTH_CLIENT_ID =
  REACT_APP_AUTH_CLIENT_ID || '3q85t0fd34d65shih6vlklpehh';

export const AUTH_REDIRECT_URI =
  REACT_APP_AUTH_REDIRECT_URI || 'https://localhost:3000/login';

export const ROUTE = {
  AUTHENTICATED: '/',
  UNAUTHENTICATED: '/login'
};

import { getTemperatureCurrent, getTemperatureRequired } from 'api';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  TemperatureCurrentResponse,
  TemperatureRequiredResponse
} from 'typings';

export const useTemperatureCurrent = (
  options: UseQueryOptions<TemperatureCurrentResponse> = {}
) =>
  useQuery<TemperatureCurrentResponse>(
    'temperature-current',
    getTemperatureCurrent,
    options
  );

export const useTemperatureRequired = () =>
  useQuery<TemperatureRequiredResponse>(
    'temperature-required',
    getTemperatureRequired
  );

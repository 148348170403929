type Props = {
  className: string;
  size: number;
  temperature: number;
};

export const Temperature: React.FC<Props> = ({
  className,
  size,
  temperature
}) => (
  <div className={`flex font-bold ${className}`}>
    <div
      style={{
        fontSize: size,
        lineHeight: `${size}px`
      }}
    >
      {temperature}
    </div>
    <div
      style={{
        fontSize: size / 2
      }}
    >
      ºC
    </div>
  </div>
);

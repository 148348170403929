import { Button } from 'components';
import { ROUTE } from 'config/auth';
import { useAuth } from 'hooks';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoSmartpool } from './logo-smartpool.svg';
import logosPartners from './logos-partners.png'; // Tell webpack this JS file uses this image

export const LoginScreen: React.FC = () => {
  const history = useHistory();
  const { signin, user } = useAuth();

  useEffect(() => {
    if (user) {
      history.replace(ROUTE.AUTHENTICATED);
    }
  }, [history, user]);

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <LogoSmartpool className="mb-8" style={{ maxWidth: 160 }} />
      <Button
        onClick={() => {
          signin?.();
        }}
        title="Log in"
        variant="primary"
      />
      <div
        className="mt-8"
        style={{
          maxWidth: 232
        }}
      >
        <p className="mb-8 opacity-50 text-center">Smartpool is powered by</p>
        <img alt="Logos partners" src={logosPartners} />
      </div>
    </div>
  );
};

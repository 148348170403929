import { appApi } from 'api';
import { ScheduleEnabledResponse, ScheduleResponse } from 'typings';

export const getSchedule = () =>
  appApi.get<ScheduleResponse>(`/schedule`).then((response) => response.data);

export const postSchedule = (data: ScheduleResponse) =>
  appApi.post(`/schedule`, data).then((response) => response.data);

export const getScheduleEnabled = () =>
  appApi
    .get<ScheduleEnabledResponse>(`/schedule-enabled`)
    .then((response) => response.data);

export const postScheduleEnabled = (data: ScheduleEnabledResponse) =>
  appApi.post(`/schedule-enabled`, data).then((response) => response.data);

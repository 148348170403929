import { setAccessToken, appApi } from 'api';
import { AUTH_CLIENT_ID, AUTH_HOST, AUTH_REDIRECT_URI } from 'config/auth';
import React, { createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const extractToken = (hash: string) => {
  const urlParams = hash.split('#')[1];
  const params = new URLSearchParams(urlParams);
  const token = params.get('access_token');

  return token;
};

type ContextProps = {
  signin: () => void;
  signout: () => void;
  user: string | null;
};

export const UserContext = createContext<Partial<ContextProps>>({});

export const UserProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const token = extractToken(location.hash);

  const [user, setUser] = React.useState<string | null>(null);
  const signin = () => {
    const params = new URLSearchParams({
      client_id: AUTH_CLIENT_ID,
      response_type: 'token',
      scope: 'smartpool/user',
      redirect_uri: AUTH_REDIRECT_URI
    });

    window.location.href = `https://${AUTH_HOST}/login/?${params.toString()}`;
  };
  const signout = () => {
    localStorage.removeItem('accessToken');
    setUser(null);
  };
  useEffect(() => {
    appApi.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          signout();
          return error;
        }
        return false;
      }
    );
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem('accessToken', token);
      setUser(token);
    }
  }, [token]);

  useEffect(() => {
    setAccessToken(user);
  }, [user]);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    setUser(accessToken);
  }, []);
  return (
    <UserContext.Provider value={{ user, signin, signout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('`useAuth` must be within a `UserProvider`');
  }
  return context;
};

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEventHandler } from 'react';
import './style.css';

type Props = {
  isOn: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  id: string;
};

export const Switch: React.FC<Props> = ({ id, isOn, onChange }) => (
  <>
    <input
      checked={isOn}
      onChange={onChange}
      className="react-switch-checkbox"
      name="react-switch-new"
      id={`react-switch-new-${id}`}
      type="checkbox"
    />
    <label
      className={`
        react-switch-label
        ${isOn ? 'active' : ''}
      `}
      htmlFor="react-switch-new"
    >
      <div className="react-switch-button">
        <div className="flex flex-row h-full py-1 w-full justify-evenly">
          <div className="ripple" />
          <div className="ripple" />
        </div>
      </div>
    </label>
  </>
);

type ButtonVariant = 'default' | 'primary' | 'ghost';

type Props = {
  className?: string;
  onClick: () => void;
  title?: string;
  variant?: ButtonVariant;
} & React.HTMLProps<HTMLButtonElement>;

const getVariantClassname = (variant: ButtonVariant) => {
  let className = '';
  switch (variant) {
    case 'ghost':
      className = 'text-black';
      break;
    case 'primary':
      className = 'bg-primary hover:bg-primary-light text-white';
      break;
    default:
      className =
        'border border-gray-300 hover:border-gray-400 bg-gray-100 hover:bg-gray-200 text-black text-opacity-80 transition-all';
      break;
  }
  return className;
};

export const Button: React.FC<Props> = ({
  className = '',
  children,
  onClick,
  style,
  title,
  variant = 'default'
}) => (
  <button
    className={`
      ${getVariantClassname(variant)} 
      py-1 px-2 rounded text-sm 
      ${className}
    `}
    onClick={onClick}
    style={style}
    type="button"
  >
    {title || children}
  </button>
);
